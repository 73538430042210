import { FC } from "react"
import { format, isAfter, isBefore, parseISO } from "date-fns"
import { Text } from '@consta/uikit/Text';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { IconTarget } from '@consta/icons/IconTarget';
import { MasterRequest } from "../../pages/master/home/types";
import { DriverPassengerRequest } from "../../pages/driver-passenger/home/types";
import { Space } from "../Space";
import "./style.css";


interface Props {
    request: MasterRequest | DriverPassengerRequest
}

interface Item {
    id: string
    title: string
    date: Date
    endDate: Date | undefined
    completed: boolean
    notPerformed: boolean
    inProgress: boolean
    isOperationSlot: boolean,
    isStartLocation: boolean,
}

export const PassengerRequestTimeline: FC<Props> = ({ request }) => {
    const items: Item[] = []

    const firstTransportation = (request.pickUpLocation?.planSlots as any[]).find((element: any) => element.type === 'ResourceTransportationSlot')

    request.pickUpLocation?.planSlots.forEach(element => items.push({
        id: element.id,
        date: parseISO(element.beginDate),
        endDate: factSlotEndDate({ slotId: element.id }),
        completed: element.state === 'COMPLETED',
        inProgress: element.state === 'IN_PROGRESS',
        notPerformed: element.state === 'NOT_PERFORMED',
        title: element.type === 'ResourceOperationStepSlot' ? `Посадка: ${element.titleOf}` : `${element.titleOf} -> ${element.titleTo}`,
        isOperationSlot: element.type === 'ResourceOperationStepSlot',
        isStartLocation: false,
    }))


    request.dropLocations?.map(element => element.planSlots).flat().forEach((element: any) => items.push({
        id: element.id,
        date: parseISO(element.beginDate),
        endDate: factSlotEndDate({ slotId: element.id }),
        completed: element.state === 'COMPLETED',
        inProgress: element.state === 'IN_PROGRESS',
        notPerformed: element.state === 'NOT_PERFORMED',
        title: element.type === 'ResourceOperationStepSlot' ? `Высадка: ${element.titleOf}` : `${element.titleOf} -> ${element.titleTo}`,
        isOperationSlot: element.type === 'ResourceOperationStepSlot',
        isStartLocation: false,
    }))

    items.sort((a, b) => a.date.getTime() - b.date.getTime())

    function factSlotEndDate({ slotId }: { slotId: string }): Date | undefined {
        const pickuUp = (request.pickUpLocation?.factSlots as any[]).find((element: any) => element.id === slotId)?.endDate
        if (pickuUp) {
            return parseISO(pickuUp)
        }

        const drop = request.dropLocations?.map((element: any) => element.factSlots).flat().find((element: any) => element.id === slotId)?.endDate
        if (drop) {
            return parseISO(drop)
        }
        return undefined
    }

    function stepProgressClassName(item: Item): string {
        if (item.inProgress) {
            return 'Loading'
        }

        if (item.completed) {
            return 'LoadingFinished'
        }

        return 'LoadingNotStarted'
    }

    if (firstTransportation) {
        items.unshift({
            id: '0',
            date: new Date(),
            endDate: new Date(),
            completed: false,
            inProgress: false,
            notPerformed: false,
            title: firstTransportation.titleOf,
            isOperationSlot: true,
            isStartLocation: true,
        })
    }

    return <Space
        direction="vertical"
        style={{
            position: 'relative',
        }}
    >
        {items.map((item, index) => {
            return <div key={item.id}>
                <Space size="l">
                    {!item.isOperationSlot ?
                        <div
                            className={stepProgressClassName(item)}
                            style={{
                                width: 4,
                                height: '34%',
                                left: 12,
                                right: '50%',
                                position: 'absolute',
                                marginBottom: 4
                            }}>

                        </div>
                        : <div id={`timeline-point-${index}`}>
                            {(!item.completed && !item.inProgress && !item.notPerformed) ? <IconTarget view="ghost" size="l" /> : <></>}

                            {(item.notPerformed) ? <IconTarget view="alert" size="l" /> : <></>}

                            {(item.completed) ? <IconTarget view="success" size="l" /> : <></>}

                            {item.inProgress ? <ProgressSpin size="l" /> : <></>}
                        </div>}

                    <div
                        id={`timeline-title-${index}`}
                        style={{ marginLeft: item.isOperationSlot ? 0 : 48 }}
                    >
                        <Text view='primary' weight="semibold" size='s'>{item.title}</Text>
                        <Space>
                            {
                                item.isStartLocation ? <Text view='secondary' size='s'>Начало маршрута</Text> :
                                    <Text view='secondary' size='s'>{format(item.date, "HH:mm")}</Text>
                            }
                            {item.endDate && isBefore(item.endDate, item.date) ? <Text size='s' view='success' weight="semibold">{format(item.endDate, "HH:mm")}</Text> : <></>}
                            {item.endDate && isAfter(item.endDate, item.date) ? <Text size='s' view='alert' weight="semibold">{format(item.endDate, "HH:mm")}</Text> : <></>}
                        </Space>
                    </div>
                </Space>
            </div>;
        })}
    </Space>

}