import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import { store } from './store';
import { RouterProvider } from 'react-router-dom';
import { Router } from './Router';
import { App } from './App';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { toast, Toaster, ToastBar } from 'react-hot-toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>

    <App>

      <Theme preset={presetGpnDefault}>

        <Toaster>
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== 'loading' && t.className !== 'notifictaion-toast' && (
                    <button onClick={() => toast.dismiss(t.id)}>X</button>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>

        <RouterProvider router={Router} />

      </Theme>
    </App>

  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();
