import { useSelector } from "react-redux"
import { RootState } from "../../../../../store"
import { EquipmentState } from "../../../../../components/EquipmentStateLabel/types"
import { Text } from '@consta/uikit/Text';
import { SlotType } from "../../../types";


export const EquipmentStateAlertLabel = () => {
    const equipmentState = useSelector((state: RootState) => state.driver.equipmentState)
    const currentSlot = useSelector((state: RootState) => state.driver.currentSlot)

    switch (equipmentState) {
        case EquipmentState.STATE_ARRIVAL_UNCONFIRMED: {
            return <Text
                view="alert"
                weight="semibold"
                size="s"
                style={{
                    padding: '4px',
                    border: '4px',
                    borderRadius: '4px',
                    position: "absolute",
                    left: "60px",
                    right: "50px",
                    top: "10px",
                    backgroundColor: "#ffffff",
                    textAlign: 'center'
                }}
            >
                Необходимо подтвердить прибытие мастером бригады
            </Text>
        }
        case EquipmentState.STATE_WAITING: {
            if (currentSlot?.type === SlotType.ResourceOperationStepSlot) {
                return <Text
                view="alert"
                weight="semibold"
                size="s"
                style={{
                    padding: '4px',
                    border: '4px',
                    borderRadius: '4px',
                    position: "absolute",
                    left: "60px",
                    right: "50px",
                    top: "10px",
                    backgroundColor: "#ffffff",
                    textAlign: 'center'
                }}
            >
                Прибытие СПТ подтверждено, ожидание начала работ
            </Text>
            } else {
                return <></>
            }
        }
        case EquipmentState.STATE_WORK_IN_PROGRESS: {
            if (currentSlot?.type === SlotType.ResourceOperationStepSlot) {
                return <Text
                view="alert"
                weight="semibold"
                size="s"
                style={{
                    padding: '4px',
                    border: '4px',
                    borderRadius: '4px',
                    position: "absolute",
                    left: "60px",
                    right: "50px",
                    top: "10px",
                    backgroundColor: "#ffffff",
                    textAlign: 'center'
                }}
            >
                СПТ выполняет работы в текущий момент
            </Text>
            } else {
                return <></>
            }
        }
        default: {
            return <></>
        }
    }
}