import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
import { keycloak } from './Keycloak'
import { StorageKeys } from './utils/storage-keys'
import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import toast, { ErrorIcon } from 'react-hot-toast'
import { Space } from './components/Space'

export const baseQuery = fetchBaseQuery({
    baseUrl: '/api/rest/core',
    prepareHeaders: (headers) => {
        if (keycloak) {
            headers.set('Authorization', `Bearer ${keycloak.token}`)
        }
        const xsrf = Cookies.get('XSRF-TOKEN')
        if (xsrf) {
            headers.set('x-xsrf-token', xsrf)
        }
        if (localStorage.getItem(StorageKeys.deviceId)) {
            headers.set('X-Gateway-Mobile-Deviceid', localStorage.getItem(StorageKeys.deviceId)!)
        }
    },
})

export const baseErrorHandler: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            if (action.payload.status === 422) {
                // toast.error(action.payload.data.header)
                // toast.custom(<div>{action.payload.data.header}</div>);
                toast(
                    () => (
                        <Space size='2xs' direction='vertical'>
                            <Space size='2xs'>
                                <ErrorIcon />
                                <b>{action.payload.data.title}</b>
                            </Space>
                            {action.payload.data.header}
                        </Space>
                    ),
                );

            }
        }
        return next(action)
    }