import { FC, useState } from "react";
import { useGetRequestPurposesQuery } from "../../../../../../mdm/services"
import { Space } from "../../../../../../components/Space";
import { SkeletonBrick } from "@consta/uikit/Skeleton";
import { ErrorDataPlaceholder } from "../../../../../../components/ErrorDataPlaceholder";
import { EmptyDataPlaceholder } from "../../../../../../components/EmptyDataPlaceholder";
import { TextField } from "@consta/uikit/TextField";
import { IconSearchStroked } from '@consta/icons/IconSearchStroked';
import { Text } from "@consta/uikit/Text"
import { Divider } from "../../../../../../components/Divider";
import { MdmRequestPurposes } from "../../../../../../mdm/types";

interface Props {
    onSelect: (item: MdmRequestPurposes) => void
}

export const PurposeItemList: FC<Props> = ({ onSelect }) => {
    const { data, isLoading, isError } = useGetRequestPurposesQuery()

    const [searchValue, setSearchValue] = useState<string | null>(null);
    const handleChange = ({ value }: { value: string | null }) => {
        setSearchValue(value)
    };

    function filteredData() {
        if (data) {
            return data.filter((equipment) => {
                if (!!!searchValue || searchValue === '') {
                    return equipment;
                } else {
                    return equipment.purpose.toLowerCase().includes(searchValue);
                }
            })
        } else {
            return []
        }
    }

    return <div>
        {(!isError && (!data || isLoading)) &&
            <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                }}
            >
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
                <SkeletonBrick height={40} />
            </Space>
        }

        {isError && <ErrorDataPlaceholder
            title="Произошла ошибка"
            description="Не удалось загрузить список причин"
        />}

        {(data && !isLoading && !isError) && <Space
            direction="vertical"

        >
            <div style={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            >
                <TextField
                    width="full"
                    onChange={handleChange}
                    value={searchValue}
                    type="text"
                    placeholder="Поиск"
                    withClearButton
                    leftSide={IconSearchStroked}
                />
            </div>
            <div style={{
                marginBottom: 'var(--space-m)',
                paddingTop: 50,
                paddingBottom: 60,
            }}>
                {filteredData().map((item, index) => {
                    return <div
                        style={{
                            paddingLeft: 'var(--space-l)',
                            paddingRight: 'var(--space-l)',
                        }}
                        key={item.id}
                        onClick={() => { onSelect(item) }}
                    >
                        <Text style={{ paddingTop: 'var(--space-s)', paddingBottom: 'var(--space-s)' }} >{item.purpose}</Text>
                        {index !== data.length - 1 && <Divider />}
                    </div>
                })
                }
            </div>

        </Space>}

        {(data && data.length === 0 && !isLoading) && <EmptyDataPlaceholder
            title="Здесь пусто"
            description="У вас нет доступных причин"
        />}

    </div>
}