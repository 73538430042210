import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { ConfirmResultRequest, DriverStartDowntimeRequest, LoadingCompletedRequest, LoadingStartedRequest, MovementCompletedRequest, MovementStartedRequest, RideCompletedRequest, RideContinuedRequest, RideStartedRequest } from './types'
import { offlineMode } from '../../../offline-mode/core'
import { OfflineEntity } from '../../../offline-mode/types'
import { checkConnection } from '../../../utils/connection'
import { ApiRoutes } from '../../../utils/api-routes'

export const driverStatesApi = createApi({
    reducerPath: 'driverScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        movementStarted: builder.mutation<any, MovementStartedRequest>({
            async queryFn(request, { getState }, _extraOptions, fetchWithBQ) {
                const result = await fetchWithBQ({
                    url: ApiRoutes.movementStarted,
                    method: 'POST',
                    body: request
                })

                // if (result.meta?.response?.status === 504 || !checkConnection()) {
                //     const entity = {
                //         date: new Date().toISOString(),
                //         body: request,
                //         event: 'movementStarted',
                //         url: '/mobile/v2/driver/movementStarted',
                //         isError: false,
                //         isSend: false,
                //     } as OfflineEntity
                //     offlineMode?.save(entity)
                // }

                if (result.error) {
                    return { error: result.error }
                }

                return { data: result.data }
            },
        }),
        movementCompleted: builder.mutation<any, MovementCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.movementCompleted,
                method: 'POST',
                body: request
            }),
        }),
        loadingStarted: builder.mutation<any, LoadingStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.loadingStarted,
                method: 'POST',
                body: request
            }),
        }),
        loadingCompleted: builder.mutation<any, LoadingCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.loadingCompleted,
                method: 'POST',
                body: request
            }),
        }),
        startWork: builder.mutation<void, undefined>({
            query: () => ({
                url: ApiRoutes.startWork,
                method: 'POST',
            }),
        }),
        endWork: builder.mutation<void, undefined>({
            query: () => ({
                url: ApiRoutes.endWork,
                method: 'POST',
            }),
        }),
        startDowntime: builder.mutation<void, DriverStartDowntimeRequest>({
            query: (request) => ({
                url: ApiRoutes.startDowntime,
                method: 'POST',
                body: request
            }),
        }),
        endDowntime: builder.mutation<void, string>({
            query: (id) => ({
                url: ApiRoutes.endDowntime(id),
                method: 'POST',
            }),
        }),
        confirmResult: builder.mutation<void, ConfirmResultRequest>({
            query: (request) => ({
                url: ApiRoutes.confirmResult,
                method: 'POST',
                body: request,
            }),
        }),
        rideStarted: builder.mutation<void, RideStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideStarted,
                method: 'POST',
                body: request,
            }),
        }),
        rideContinued: builder.mutation<void, RideContinuedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideContinued,
                method: 'POST',
                body: request,
            }),
        }),
        rideCompleted: builder.mutation<void, RideCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideCompleted,
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const {
    useMovementStartedMutation,
    useMovementCompletedMutation,
    useLoadingStartedMutation,
    useLoadingCompletedMutation,
    useStartWorkMutation,
    useEndWorkMutation,
    useStartDowntimeMutation,
    useEndDowntimeMutation,
    useConfirmResultMutation,
    useRideCompletedMutation,
    useRideContinuedMutation,
    useRideStartedMutation,
} = driverStatesApi