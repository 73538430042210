import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { EditMasterMaterialRequest, NewMasterMaterialRequest, UpdateMasterMaterialRequest } from './types'
import { MasterRequest } from '../../home/types'
import { MdmMaterial } from '../../../../mdm/types'
import { parseISO } from 'date-fns'
import { CalculateDurationFromMinutes } from '../../../../utils/dates'
import { ShuttleValue } from '../components/SelectShuttle/types'
import { ShuttleTypeValue } from '../components/SelectShuttleType/types'
import { ApiRoutes } from '../../../../utils/api-routes'

export const masterMaterialRequestApi = createApi({
    reducerPath: 'masterMaterialRequestApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createMaterialRequest: builder.mutation<undefined, NewMasterMaterialRequest>({
            query: (request) => ({
                url: ApiRoutes.createMaterialRequest,
                method: 'POST',
                body: request
            }),
        }),
        updateMaterialRequest: builder.mutation<undefined, UpdateMasterMaterialRequest>({
            query: (request) => ({
                url: ApiRoutes.updateMaterialRequest(request.id),
                method: 'POST',
                body: request.body
            }),
        }),
        getMaterialRequestDetails: builder.query<EditMasterMaterialRequest, string | undefined>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const detailsResponse = await fetchWithBQ(ApiRoutes.getJobRequestDetails(_arg)) 
                if (detailsResponse.error) {
                    return { error: detailsResponse.error }
                }
                const detailsData = detailsResponse.data as MasterRequest

                const materialsResponse = await fetchWithBQ(ApiRoutes.getMaterials)
                if (materialsResponse.error) {
                    return { error: materialsResponse.error }
                }
                const materialsData = materialsResponse.data as MdmMaterial[]

                const result = {
                    state: detailsData.state,
                    sheduleId: detailsData.jobs.at(0)?.id,
                    dateTimeOf: parseISO(detailsData.beginDate),
                    dateTimeTo: parseISO(detailsData.endDate),
                    materials: detailsData.jobs.at(0)?.materialRequirements.map((material) => ({
                        material: materialsData.find(element => element.id === material.materialId),
                        count: material.qty
                    })),
                    equipment: detailsData.jobs.at(0)?.equipmentTypeParams.map((equipmentType) => ({
                        equipmentType: {
                            id: equipmentType.equipmentTypeId,
                            title: equipmentType.equipmentTitle
                        },
                        count: equipmentType.count
                    })),
                    durationLoad: CalculateDurationFromMinutes({ minutes: detailsData.jobs.at(0)?.loadingDuration ?? 0 }),
                    durationUnload: CalculateDurationFromMinutes({ minutes: detailsData.jobs.at(0)?.unloadingDuration ?? 0 }),
                    shuttle: detailsData.jobs.at(0)?.isShuttle ? ShuttleValue.SHUTTLE : ShuttleValue.SINGLE,
                    maxResourceCount: detailsData.jobs.at(0)?.maxResourceCount,
                    shuttleType: detailsData.jobs.at(0)?.uniformDistribution ? ShuttleTypeValue.EVENLY : ShuttleTypeValue.EARLIER,
                    workPlace: {
                        oilField: {
                            title: detailsData.jobs.at(0)?.wellPadGroupTitle
                        },
                        cluster: {
                            id: detailsData.jobs.at(0)?.poiId,
                            title: detailsData.jobs.at(0)?.wellPadTitle
                        },
                        well: detailsData.jobs.at(0)?.wellId !== "00000000-0000-0000-0000-000000000000" ? {
                            id: detailsData.jobs.at(0)?.wellId,
                            title: detailsData.jobs.at(0)?.wellTitle
                        } : undefined
                    }
                } as EditMasterMaterialRequest

                return { data: result }
            }
        }),
    }),
})

export const {
    useCreateMaterialRequestMutation,
    useGetMaterialRequestDetailsQuery,
    useUpdateMaterialRequestMutation,
} = masterMaterialRequestApi