import { FC, useState } from "react"
import { MdmServiceBlock } from "../../../../../../mdm/types"
import { Text } from "@consta/uikit/Text"
import { Divider } from "../../../../../../components/Divider"
import { useGetServiceBlocksQuery } from "../../../../../../mdm/services"
import { Space } from "../../../../../../components/Space"
import { SkeletonBrick } from "@consta/uikit/Skeleton"
import { ErrorDataPlaceholder } from "../../../../../../components/ErrorDataPlaceholder"
import { TextField } from "@consta/uikit/TextField";
import { IconSearchStroked } from '@consta/icons/IconSearchStroked';


interface Props {
    operationId: string
    onSelect: (item: MdmServiceBlock) => void
}

export const ServiceBlocksList: FC<Props> = ({ operationId, onSelect }) => {
    const { data, isLoading, isError } = useGetServiceBlocksQuery(operationId)

    const [searchValue, setSearchValue] = useState<string | null>(null);

    const handleChange = ({ value }: { value: string | null }) => {
        setSearchValue(value)
    };

    function filteredData() {
        if (data) {
            return data.filter((block) => {
                if (!!!searchValue || searchValue === '') {
                    return block;
                } else {
                    return block.title.toLowerCase().includes(searchValue);
                }
            })
        } else {
            return []
        }
    }
    
    return <div style={{ paddingBottom: '120' }}>
        {isLoading && !data && <Space
            size="xs"
            direction="vertical"
            style={{
                paddingLeft: 'var(--space-s)',
                paddingRight: 'var(--space-s)',
            }}>
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
            <SkeletonBrick height={40} />
        </Space>}

        {isError && <ErrorDataPlaceholder title={"Ошибка"} description={"Не удалось загрузить данные"} />}

        {(data && !isLoading && !isError) && <Space
            direction="vertical"

        >
            <div style={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            >
                <TextField
                    width="full"
                    onChange={handleChange}
                    value={searchValue}
                    type="text"
                    placeholder="Поиск"
                    withClearButton
                    leftSide={IconSearchStroked}
                />
            </div>
            <div style={{
                marginBottom: 'var(--space-m)',
                paddingTop: 50,
                paddingBottom: 60,
            }}>
                {filteredData().map((item, index) => {
                    return <div
                        style={{
                            paddingLeft: 'var(--space-l)',
                            paddingRight: 'var(--space-l)',
                        }}
                        key={item.id}
                        onClick={() => onSelect(item)}
                    >
                        {index === 0 && <div style={{ height: 'var(--space-m)' }} />}
                        <Text>{item.title}</Text>
                        {index !== data.length - 1 && <Divider style={{ marginTop: 'var(--space-s)', marginBottom: 'var(--space-s)' }} />}
                    </div>
                })
                }
            </div>
        </Space>}
    </div>
}