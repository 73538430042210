import { Fragment, useEffect, useState } from "react"
import { Divider } from "../../../../components/Divider"
import { ErrorDataPlaceholder } from "../../../../components/ErrorDataPlaceholder"
import { EmptyDataPlaceholder } from "../../../../components/EmptyDataPlaceholder"
import { SkeletonBrick } from '@consta/uikit/Skeleton';
import { Space } from "../../../../components/Space"
import { useApproveDeviceBindingMutation, useGetBindEquipmentRequestsQuery } from "./services";
import { BindResorcesRequest } from "./types";
import { RequestItem } from "./components/RequestItem";
import { DeclineBindingRequestDialog } from "./components/DeclineBindingRequestDialog";
import { Button } from "@consta/uikit/Button";
import toast from 'react-hot-toast';


export const MechanicBindEquipmetRequests = () => {
    const { data, error, isFetching, refetch } = useGetBindEquipmentRequestsQuery()
    const [approveRequest, { isLoading:approveLoading, isError: approveError, isSuccess: approveSuccess }] = useApproveDeviceBindingMutation()

    const [selectedRequests, setSelectedRequests] = useState<BindResorcesRequest[]>([])
    const [request, setRequest] = useState<BindResorcesRequest | undefined>(undefined)

    useEffect(() => {
        if (approveError) {
            toast.error('Произошла ошибка. Не удалось утвердить заявку(и)')
        }
        if (approveSuccess) {
            refetch()
        }
    }, [approveError, approveSuccess, refetch])

    useEffect(() => {
        setSelectedRequests([])
    }, [isFetching])

    return <div>        
        {(!error && (!data || isFetching)) &&
            <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                }}
            >
                <SkeletonBrick height={30} />
                <SkeletonBrick height={30} />
                <SkeletonBrick height={30} />
                <SkeletonBrick height={30} />
            </Space>
        }

        {error && <ErrorDataPlaceholder
            title="Произошла ошибка"
            description="Не удалось загрузить список заявок"
        />}

        {(data && !isFetching) && <div style={{
            marginTop: 'var(--space-m)',
            marginBottom: 'var(--space-m)',
            paddingBottom: 60,
        }}>
            {data.map((request, index) => {
                return <Fragment key={request.resourceBindingId}>
                    <RequestItem
                        onSelect={() => {
                            if (selectedRequests.includes(request)) {
                                setSelectedRequests(selectedRequests.filter(a => a.resourceBindingId !== request.resourceBindingId))
                            } else {
                                setSelectedRequests([...selectedRequests, request])
                            }
                        }}
                        onReject={() => {
                            setRequest(request)
                         }}
                        item={request}
                        checked={selectedRequests.includes(request)} />
                    {index !== data.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                </Fragment>
            })
            }
        </div>}

        {(data && data.length === 0 && !isFetching) && <EmptyDataPlaceholder
            title="Здесь пусто"
            description="У вас нет заявок для обработки"
        />}

        {request && <DeclineBindingRequestDialog
            request={request}
            onClose={(update) => {
                setRequest(undefined)
                if (update) {
                    refetch()
                }
            }}
        />}

        <Button
            form="round"
            label="Утвердить "
            disabled={selectedRequests.length === 0}
            loading={approveLoading}
            style={{
                position: "fixed",
                bottom: 'var(--space-l)',
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            onClick={() => approveRequest({
                resourceBindingIds: selectedRequests.map((request) => request.resourceBindingId)
            })}
        />
    </div>
}