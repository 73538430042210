import { createApi } from '@reduxjs/toolkit/query/react'
import { MdmActivityTypes, MdmCluster, MdmEquipmentItem, MdmEquipmentType, MdmMaterial, MdmOilField, MdmRequestPurposes, MdmResourceState, MdmServiceBlock, MdmServiceOperation, MdmServiceType, MdmSystemSetting, MdmWell, MdmWorkShift } from './types'
import { baseQuery } from '../base-service'
import { StorageKeys } from '../utils/storage-keys'
import { ApiRoutes } from '../utils/api-routes'


export const mdmApi = createApi({
    reducerPath: 'ssmMdmApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getWorkShift: builder.query<MdmWorkShift, void>({
            query: () => ApiRoutes.getWorkShift,
        }),
        getDisengageTypes: builder.query<Record<string, string>, void>({
            query: () => ApiRoutes.getDisengageTypes,
        }),
        getServiceTypes: builder.query<MdmServiceType[], void>({
            query: () => ApiRoutes.getServiceTypes,
            transformResponse: (response: MdmServiceType[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getServiceOperations: builder.query<MdmServiceOperation[], string>({
            query: (serviceTypeId) => ApiRoutes.getServiceOperations(serviceTypeId),
            transformResponse: (response: MdmServiceOperation[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getServiceBlocks: builder.query<MdmServiceBlock[], string>({
            query: (operationId) => ApiRoutes.getServiceBlocks(operationId),
            transformResponse: (response: MdmServiceBlock[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getOilFileds: builder.query<MdmOilField[], void>({
            query: () => ApiRoutes.getOilFileds,
            transformResponse: (response: MdmOilField[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getClusters: builder.query<MdmCluster[], string>({
            query: (oilFieldId) => ApiRoutes.getClusters(oilFieldId),
            transformResponse: (response: MdmCluster[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getWells: builder.query<MdmWell[], string>({
            query: (clusterId) =>ApiRoutes.getWells(clusterId),
            transformResponse: (response: MdmWell[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getEquipmentTypes: builder.query<MdmEquipmentType[], void>({
            query: () => ApiRoutes.getEquipmentTypes,
            transformResponse: (response: MdmEquipmentType[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getMaterials: builder.query<MdmMaterial[], void>({
            query: () => ApiRoutes.getMaterials,
            transformResponse: (response: MdmMaterial[]) => response.sort((a, b) => b.title > a.title ? -1 : 1)
        }),
        getEquipmentItems: builder.query<MdmEquipmentItem[], void>({
            query: () => ApiRoutes.getEquipmentItems,
            transformResponse: (response: MdmEquipmentItem[]) => response.sort((a, b) => b.registrationPlateNumber > a.registrationPlateNumber ? -1 : 1)
        }),
        geActivityTypes: builder.query<MdmActivityTypes, string>({
            query: (id) => ApiRoutes.geActivityTypes(id),
        }),
        getResourceState: builder.query<MdmResourceState[], void>({
            query: () => ApiRoutes.getResourceState,
        }),
        getRequestPurposes: builder.query<MdmRequestPurposes[], void>({
            query: () => ApiRoutes.getRequestPurposes,
            transformResponse: (response: MdmRequestPurposes[]) => response.sort((a, b) => b.purpose > a.purpose ? -1 : 1)
        }),
        getSystemSettings: builder.query<MdmSystemSetting[], void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const response = await fetchWithBQ(ApiRoutes.getSystemSettings)
                if (response.error) {
                    return { error: response.error }
                }

                const settings = response.data as MdmSystemSetting[]
                localStorage.setItem(StorageKeys.passangerModeEnabled, settings.find(element => element.key === 'PASSENGER_MODE_ENABLED')?.value ?? 'false')

                return { data: settings }
            }
        }),
        getReserveTypes: builder.query<Record<string, string>, void>({
            query: () => ApiRoutes.getReserveTypes,
        }),
    }),
})

export const {
    useGetWorkShiftQuery,
    useGetDisengageTypesQuery,
    useGetServiceTypesQuery,
    useGetServiceOperationsQuery,
    useGetServiceBlocksQuery,
    useGetOilFiledsQuery,
    useGetClustersQuery,
    useGetWellsQuery,
    useGetEquipmentTypesQuery,
    useGetMaterialsQuery,
    useGetEquipmentItemsQuery,
    useGeActivityTypesQuery,
    useGetResourceStateQuery,
    useGetRequestPurposesQuery,
    useGetSystemSettingsQuery,
    useGetReserveTypesQuery,
} = mdmApi