import { FC, useEffect } from "react";
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { Button } from "@consta/uikit/Button";
import { useEndWorkMutation } from "../states/services";
import toast from 'react-hot-toast';
import { logout } from "../../../user/services";
import { keycloak } from "../../../Keycloak";
import { Space } from "../../../components/Space";


interface Props {
    isOpen: boolean
    onClickOutside: () => void
}

export const DriverLogoutDialog: FC<Props> = ({ isOpen, onClickOutside }) => {
    const [endWork, {isLoading,  isError , isSuccess }] = useEndWorkMutation()

    useEffect(() => {
        const doLogout = async () => {
            await logout()
            await keycloak?.logout()
          };

        if (isError) {
            toast.error('Произошла ошибка. Не удалось завершить смену')
        }
        if (isSuccess) {
            doLogout()
        }
    }, [isError, isSuccess])

    return <>
        <Modal
            onClickOutside={() => onClickOutside()}
            isOpen={isOpen}
            hasOverlay
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="l" view="primary" lineHeight="m">Выход из приложения, выберите один из вариантов</Text>
                <Space
                    direction="vertical"
                    style={{ textAlign: 'center', paddingTop: 10 }}>
                    <Button
                        loading={isLoading}
                        size="l"
                        view="primary"
                        label="Завершить смену"
                        width="default"
                        onClick={() => {
                            endWork(undefined)
                        }}
                    />
                    <Button
                        size="l"
                        view="ghost"
                        label="Выйти"
                        width="default"
                        onClick={async () => {
                            await logout()
                            await keycloak?.logout()
                        }}
                    />
                </Space>
            </div>
        </Modal>
    </>
}