import { Controller, useFormContext } from "react-hook-form"
import { RadioGroup } from '@consta/uikit/RadioGroup';
import { FieldLabel } from '@consta/uikit/FieldLabel';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ShuttleTypeValue } from "./types";


const items: ShuttleTypeValue[] = [
    ShuttleTypeValue.EARLIER,
    ShuttleTypeValue.EVENLY,
]

export const SelectShuttleType = ({
    disabled,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    ...fieldProps
}: any) => {
    const context = useFormContext()

    const renderSelectShuttleType = ({ field }: any) => {

        const { onChange, value } = field

        return <div>
            <FieldLabel required={rules?.required} className={cnMixSpace({ mB: 'xs' })}>Тип челночного завоза</FieldLabel>

            <RadioGroup
                disabled={disabled}
                value={value}
                items={items}
                getItemLabel={(item: ShuttleTypeValue) => item === ShuttleTypeValue.EARLIER ? 'Как можно раньше' : 'Равномерный'}
                onChange={(event) => {
                    onChange(event.value)
                }}
                direction="column"
            />
        </div>
    }

    return <Controller
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        control={control || context.control}
        shouldUnregister={shouldUnregister}
        render={renderSelectShuttleType}
    />
}