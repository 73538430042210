import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Space } from "../../../../../components/Space";
import { Button } from "@consta/uikit/Button";
import { SelectDestinationPointController } from "./SelectDestinationPointController";


export const SelectDestinationPoints = ({
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
}: any) => {
    const { fields, append, move, remove } = useFieldArray({
        control,
        name: name,
        rules: {
            minLength: rules?.required ? 1 : undefined,
            required: rules?.required,
        }
    });

    const context = useFormContext()

    return <Space direction="vertical" size="s">
        <>
            {fields.map((item, index) => (
                <Controller
                    key={item.id}
                    name={`${name}.${index}`}
                    rules={{
                        validate: (field) => field.oilField !== undefined && field.cluster !== undefined
                    }}
                    defaultValue={defaultValue}
                    control={control || context.control}
                    shouldUnregister={shouldUnregister}
                    render={({ field }) => SelectDestinationPointController({
                        fields,
                        field,
                        name,
                        rules,
                        index,
                        control,
                        defaultValue,
                        shouldUnregister,
                        move,
                        remove,
                    })}
                />
            ))}
        </>
        <Button
            label="+ Добавить"
            view="ghost"
            onClick={() => append({
                oilField: undefined,
                cluster: undefined,
                well: undefined,
            })} />
    </Space>
}