import { Theme, presetGpnDefault } from "@consta/uikit/Theme"
import { FC, useEffect, useRef, useState } from "react"
import { OfflineRequest } from "../types"
import { Text } from "@consta/uikit/Text"
import { AppBarBottomSheet } from "../../../../../components/AppBarBottomSheet"
import { Space } from "../../../../../components/Space"
import { Button } from "@consta/uikit/Button"
import { useApproveRequestMutation, useChangeReturnTimeMutation, useDeclineRequestMutation } from "../services"
import { formatISO, parseISO } from "date-fns"
import { RegisterPlate } from "../../../../../components/RegisterPlate"
import toast from 'react-hot-toast';
import { mdmApi } from "../../../../../mdm/services"
import { Modal } from "@consta/uikit/Modal"
import { MechanicApproveOfflineRequest } from "./MechanicApproveOfflineRequest"
import Sheet, { SheetRef } from 'react-modal-sheet';


interface Props {
    request: OfflineRequest
    onClose: (update?: boolean) => void
}

export const MechanicManageRequestDialog: FC<Props> = ({ request, onClose }) => {
    const refBottomSheet = useRef<SheetRef>();

    const [isApproveModelOpen, setIsApproveModalOpen] = useState<boolean>(false);

    const [approveRequest, { isLoading: approveLoading, isError: approveError, isSuccess: approveSuccess }] = useApproveRequestMutation()
    const [declineRequest, { isLoading: declineLoading, isError: declineError, isSuccess: declineSuccess }] = useDeclineRequestMutation()
    const [changeTimeRequest, { isLoading: changeTimeLoading, isError: changeTimeError, isSuccess: changeTimeSuccess }] = useChangeReturnTimeMutation()
    const { data: disengageTypes } = mdmApi.endpoints.getDisengageTypes.useQueryState(undefined);

    useEffect(() => {
        if (approveError) {
            toast.error('Произошла ошибка. Не удалось одобрить заявку')
        }
        if (declineError) {
            toast.error('Произошла ошибка. Не удалось отклонить заявку')
        }
        if (changeTimeError) {
            toast.error('Произошла ошибка. Не удалось изменить дату возврата')
        }
        if (approveSuccess || declineSuccess || changeTimeSuccess) {
            onClose(true)
        }
    }, [approveError, declineError, changeTimeError, approveSuccess, declineSuccess, changeTimeSuccess, onClose])

    return <>

        <Sheet
            ref={refBottomSheet}
            isOpen={true}
            onClose={() => { }}
            snapPoints={[350, 0]}
            initialSnap={0}
            style={{ zIndex: 5 }}
        >
            <Sheet.Container>
                <Sheet.Content disableDrag={true}>
                    <Sheet.Scroller draggableAt="top">
                        <Theme preset={presetGpnDefault}>
                            <div>
                                <AppBarBottomSheet
                                    title="Сход с линии"
                                    onBackPress={() => onClose(false)}
                                />
                                <Space
                                    direction="vertical"
                                    size="s"
                                    style={{
                                        paddingLeft: 'var(--space-l)',
                                        paddingRight: 'var(--space-l)',
                                        marginTop: 'var(--space-l)',
                                    }}>
                                    <Space>
                                        <Text view="primary" weight="regular">{request.equipment.name}</Text>
                                        <RegisterPlate regNumber={request.equipment.registrationPlate} />
                                    </Space>
                                    <Text
                                        view="primary"
                                        weight="semibold"
                                    >
                                        Причина: {disengageTypes ? disengageTypes[request.reason] : request.reason}
                                    </Text>
                                    {request.comment ? <Text
                                        view="primary"
                                    >
                                        Комментарий: {request.comment}
                                    </Text> : <></>}
                                    <div style={{ height: 10 }} />
                                    <Button
                                        form="round"
                                        label="Одобрить заявку"
                                        loading={approveLoading || changeTimeLoading}
                                        style={{ backgroundColor: '#09B37B' }}
                                        onClick={() => setIsApproveModalOpen(true)}
                                    />
                                    <Button
                                        form="round"
                                        loading={declineLoading}
                                        label="Отклонить заявку"
                                        style={{ backgroundColor: '#EB5757' }}
                                        onClick={() => declineRequest(request.id)}
                                    />
                                </Space>
                            </div>
                        </Theme>
                    </Sheet.Scroller>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>

        <Modal
            isOpen={isApproveModelOpen}
            hasOverlay
            onClickOutside={() => setIsApproveModalOpen(false)}
            onEsc={() => setIsApproveModalOpen(false)}
            style={{ zIndex: 100 }}>
            <MechanicApproveOfflineRequest
                returnToWorkDate={request.returnToWorkDate != null ? parseISO(request.returnToWorkDate) : undefined}
                onCancel={() => setIsApproveModalOpen(false)}
                onApprove={(returnToWorkDate) => {
                    setIsApproveModalOpen(false)

                    if (returnToWorkDate) {
                        changeTimeRequest([
                            request.id,
                            {
                                "returnToWorkTime": formatISO(returnToWorkDate)
                            }
                        ])
                    } else {
                        approveRequest(request.id)
                    }
                }}
            />
        </Modal>
    </>
}