import { FC } from "react"
import { EquipmentState } from "./types"
import { Badge } from '@consta/uikit/Badge';
import { mdmApi } from "../../mdm/services";

interface Props {
    state: EquipmentState | undefined | null
}

export const EquipmentStateLabel: FC<Props> = ({ state }) => {
    const { data } = mdmApi.endpoints.getResourceState.useQueryState(undefined);

    const name = data ? data.find(element => element.title === state)?.description ?? state : state

    const status : Record<EquipmentState, "normal" | "error" | "success" | "warning" | "system" | undefined> = {
        [EquipmentState.STATE_WAITING]: "system",
        [EquipmentState.STATE_LOADING]: "warning",
        [EquipmentState.STATE_ON_ROUTE]: "normal",
        [EquipmentState.STATE_ARRIVAL_UNCONFIRMED]: "system",
        [EquipmentState.STATE_WORK_IN_PROGRESS]: "success",
        [EquipmentState.STATE_PAUSED]: "normal",
        [EquipmentState.STATE_OUT_OF_SERVICE]: "error",
        [EquipmentState.STATE_RESERVE]: "normal"
    }

    if (state) {
        return <Badge status={status[state]} size="s" label={name!} style={{width: 'fit-content'}}/>
    } else {
        return <Badge status='system' size="s" label="null"  />
    }
}