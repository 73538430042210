import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "@consta/uikit/Button";
import { Space } from "../Space";
import { FieldLabel } from '@consta/uikit/FieldLabel';
import { SelectMaterialController } from "./components/SelectMaterialController";


export const SelectMaterial = ({
    disabled,
    title,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
}: any) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        rules: {
            minLength: rules?.required ? 1 : undefined,
            required: rules?.required,
        }
    });

    const context = useFormContext()

    return <Space direction="vertical" size="s">
        <FieldLabel required={rules?.required}>{title ?? 'Материалы'}</FieldLabel>
        <>
            {fields.map((item, index) => (
                <Controller
                    key={item.id}
                    name={`${name}.${index}`}
                    rules={{
                        validate: (field) => field.material !== undefined
                    }}
                    defaultValue={defaultValue}
                    control={control || context.control}
                    shouldUnregister={shouldUnregister}
                    render={({ field }) => SelectMaterialController({
                        disabled,
                        field,
                        rules,
                        index,
                        control,
                        defaultValue,
                        shouldUnregister,
                        remove,
                    })}
                />
            ))}
        </>
        <Button
            disabled={disabled}
            label="+ Добавить"
            view="ghost"
            onClick={() => append({
                material: undefined,
                count: 1
            })} />
    </Space>
}