import { StepEquipmentItem } from './StepEquipmentItem';
import { MasterRequestResource } from '../types';
import { FC } from 'react';
import { EmptyDataPlaceholder } from '../../../../components/EmptyDataPlaceholder';
import { MasterRequestState } from '../../home/types';


interface Props {
    resources: Record<string, MasterRequestResource>
    resourceIds: string[]
    stepState: MasterRequestState
    stepId: string
}

export const StepEquipments: FC<Props> = ({ resources, resourceIds, stepState, stepId }) => {
    return <div >
        {resourceIds.length > 0 ? resourceIds.map((resourceId, index) => {
            return <div key={resourceId} >
                <StepEquipmentItem
                    equipmentId={resourceId}
                    equipment={resources[resourceId]}
                    stepState={stepState}
                    stepId={stepId}
                />
                {index !== resourceIds.length - 1 && <div style={{ height: 10 }} />}
            </div>
        }) : <EmptyDataPlaceholder
            title={'Техника не назначена'}
            description={''}
            style={{
                padding: 10,
                backgroundColor: "#F5F5F5",
                borderRadius: 4,
            }}
        />}
    </div>
}