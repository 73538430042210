import { useSelector } from "react-redux"
import { Text } from '@consta/uikit/Text';
import { format, parseISO } from "date-fns";
import { IconAlert } from '@consta/icons/IconAlert';
import { useEffect } from "react";
import toast from 'react-hot-toast';
import { RootState } from "../../../../store";
import { useGetScheduleDataQuery } from "../../services";
import { useMovementStartedMutation } from "../services";
import { Space } from "../../../../components/Space";
import { EquipmentRoute } from "../../../../components/EquipmentRoute";
import SliderButton from "../../../../components/SliderButton";
import { Divider } from "../../../../components/Divider";


export const DriverStateStartMovement = () => {
    const currentSlot = useSelector((state: RootState) => state.driver.currentSlot)
    const [movementStarted, { isLoading: movementStartedLoading, isError: movementStartedError, isSuccess: movementStartedSuccess }] = useMovementStartedMutation()

    const { refetch: refetchSchedule, isFetching } = useGetScheduleDataQuery(undefined, { skip: !movementStartedSuccess })

    useEffect(() => {
        if (movementStartedError) {
            toast.error('Произошла ошибка. Не удалось начать движение')
        }
    }, [movementStartedError])

    useEffect(() => {
        if (movementStartedSuccess) {
            refetchSchedule()
        }
    }, [movementStartedSuccess, refetchSchedule])


    if (currentSlot) {

        return <>
            <Space
                direction="vertical"
                size="s"
                style={{ paddingTop: 10 }}
            >
                <Space
                    direction="vertical"
                    size="l"
                    style={{
                        paddingLeft: 'var(--space-l)',
                        paddingRight: 'var(--space-l)',
                    }}>

                    <Space size="xs" direction="vertical">
                        <EquipmentRoute
                            titleOf={currentSlot.titleOf}
                            titleTo={currentSlot.titleTo}
                        />
                        <Space size="xs">
                            <Text view="secondary">{format(parseISO(currentSlot.beginDate), "HH:mm")} - {format(parseISO(currentSlot.endDate), "HH:mm")}</Text>
                            {currentSlot.distance ? <Text view="secondary">{currentSlot.distance.toPrecision(2)} км</Text> : <></>}
                        </Space>
                    </Space>

                    <SliderButton
                        loading={movementStartedLoading || isFetching}
                        text='Начать движение'
                        color='#0078d2'
                        onSuccess={() => movementStarted({ slotId: currentSlot.id })}
                    />
                </Space>

                <Divider height={6} />
            </Space>
        </>
    } else {
        return <Space
            direction="vertical"
            size="s"
            style={{
                paddingTop: 30,
                alignItems: "center"
            }}>
            <IconAlert view="alert" />
            <Text view="critical">Не найдено текущее назначение</Text>
            <Divider />
        </Space>
    }
}