import { DriverPassengerRequest, DriverPassengerRequestState, DriverPassengerSlot, SlotType } from "../types"
import { DriverPassengerStateCompleteRide } from "./components/DriverPassengerStateCompleteRide"
import { DriverPassengerStateContinueRide } from "./components/DriverPassengerStateContinueRide"
import { DriverPassengerStateStartBoarding } from "./components/DriverPassengerStateStartBoarding"
import { DriverPassengerStateStartMovement } from "./components/DriverPassengerStateStartMovement"
import { DriverPassengerStateStartRide } from "./components/DriverPassengerStateStartRide"

export function mapDriverPassengerState(currentSlot: DriverPassengerSlot | undefined, request: DriverPassengerRequest) {
    const isPickup = request.pickUpLocation.planSlots.find(slot => slot.id === currentSlot?.currentSlot.id) !== undefined
    const isDrop = request.dropLocations.flatMap(element => element.planSlots).find(slot => slot.id === currentSlot?.currentSlot.id) !== undefined

    const isOperation = currentSlot?.currentSlot.type === SlotType.ResourceOperationStepSlot
    const isTransportation = currentSlot?.currentSlot.type === SlotType.ResourceTransportationSlot

    const state = currentSlot?.currentSlot.state

    const moreThenOneDrop = request.dropLocations.length > 1

    const dropOperationLength = request.dropLocations.flatMap(element => element.planSlots).filter(element => element.type === SlotType.ResourceOperationStepSlot).length
    const dropTransportationLength = request.dropLocations.flatMap(element => element.planSlots).filter(element => element.type === SlotType.ResourceTransportationSlot).length

    const isEarlierDropTransportation = request.dropLocations.flatMap(element => element.planSlots).filter(element => element.type === SlotType.ResourceTransportationSlot)?.at(0)?.id === currentSlot?.currentSlot.id

    const isLateDropTransportation = request.dropLocations.flatMap(element => element.planSlots).filter(element => element.type === SlotType.ResourceTransportationSlot)?.at(dropTransportationLength-1)?.id === currentSlot?.currentSlot.id
    const isLateDropOperation = request.dropLocations.flatMap(element => element.planSlots).filter(element => element.type === SlotType.ResourceOperationStepSlot)?.at(dropOperationLength-1)?.id === currentSlot?.currentSlot.id

    const isInProgress = state === DriverPassengerRequestState.IN_PROGRESS
    const isLockedOrDelayed = state && [DriverPassengerRequestState.DELAYED,
        DriverPassengerRequestState.LOCKED_AUTO,
        DriverPassengerRequestState.LOCKED_MANUAL,
        DriverPassengerRequestState.LOCKED].includes(state)

    // Кнопка "Старт"
    if (isPickup && isTransportation && isLockedOrDelayed) {
        return <DriverPassengerStateStartMovement />
    }

    // Кнопка "Начать ожидание"
    if (isPickup && isTransportation && isInProgress) {
        return <DriverPassengerStateStartBoarding />
    }
    if (isPickup && isOperation && isLockedOrDelayed) {
        return <DriverPassengerStateStartBoarding />
    }

    // Кнопка "Начать поездку"
    if (isPickup && isOperation && isInProgress) {
        return <DriverPassengerStateStartRide />
    }
    if (isDrop && isTransportation && isEarlierDropTransportation && isLockedOrDelayed) {
        return <DriverPassengerStateStartRide />
    }

     // Кнопка "Завершить поездку"
    if (isDrop && isTransportation && isLateDropTransportation && isInProgress) {
        return <DriverPassengerStateCompleteRide />
    }
    if (isDrop && isOperation && isLateDropOperation && isLockedOrDelayed) {
        return <DriverPassengerStateCompleteRide />
    }

    // Кнопка "Продолжить поездку"
    if (isDrop && isTransportation && moreThenOneDrop && !isLateDropTransportation && isInProgress) {
        return <DriverPassengerStateContinueRide />
    }
    if (isDrop && isOperation && moreThenOneDrop && !isLateDropOperation && isLockedOrDelayed) {
        return <DriverPassengerStateContinueRide />
    }
    if (isDrop && isTransportation && moreThenOneDrop && !isEarlierDropTransportation && isLockedOrDelayed) {
        return <DriverPassengerStateContinueRide />
    }

    return <div>Не удалось определить состояние</div>
}