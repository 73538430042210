import { Space } from "./Space"
import { Text } from '@consta/uikit/Text';
import { Button } from "@consta/uikit/Button";
import { FC } from "react";
import { IconClose } from '@consta/icons/IconClose';


interface Props {
    onClose: () => void
    title: string
}

export const BottomSheetTitleHeader: FC<Props> = ({ title, onClose }) => {
    return <div>
        <Space
            spaceBetween
            style={{
                padding: 'var(--space-s)',
            }}
        >
            <Text view="primary" weight="semibold" size="m">{title}</Text>
            <Button
                label="Закрыть"
                view="ghost"
                size='s'
                iconLeft={IconClose}
                onlyIcon
                onClick={onClose}
            />
        </Space>
        {/* <Divider /> */}
    </div>
}