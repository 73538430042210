import { useLocation, useNavigate, useParams } from "react-router"
import { AppBar } from "../../../../components/AppBar"
import { Space } from "../../../../components/Space"
import { SelectJobTask } from "../components/SelectJobTask"
import { SubmitHandler, useForm } from "react-hook-form"
import { TaskJobMaterialCount, TaskJobValue, WorkPlaceValue } from "./types"
import { Button } from "@consta/uikit/Button"
import { SelectWorkPlace } from "../../../../components/SelectWorkPlace"
import { SelectDateTime } from "../../../../components/SelectDateTime"
import { SelectEquipmentType } from "../../../../components/SelectEquipmentType"
import { Divider } from "../../../../components/Divider"
import { useCreateJobRequestMutation, useGetJobRequestDetailsQuery, useUpdateJobRequestMutation } from "./services"
import { SelectMaterial } from "../../../../components/SelectMaterial"
import { masterApi, useGetWorkPlanQuery } from "../../services"
import { useEffect, useState } from "react"
import { useMount } from "react-use"
import { StorageKeys } from "../../../../utils/storage-keys"
import toast from 'react-hot-toast';
import { SelectedEquimpentTypeCount } from "../../../../components/SelectEquipmentType/types"
import { useSelector } from "react-redux"
import { RootState } from "../../../../store"
import { RequestDetailsSkeleton } from "../components/RequestDetailsSkeleton"
import { addHours, isAfter, isBefore } from "date-fns"
import { useGeActivityTypesQuery } from "../../../../mdm/services"
import { MasterRequestState, RequestOwnerFilter } from "../../home/types"
import { CalculateRequestPriority } from "../../../../utils/workShifts"


type FormData = {
    jobTask: TaskJobValue
    workPlace: WorkPlaceValue
    dateTimeOf: Date
    dateTimeTo: Date
    equipmentTypes: SelectedEquimpentTypeCount[]
    materials: TaskJobMaterialCount[]
}

export const MasterJobRequest = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { requestId } = useParams()

    const isCopyMode = location.pathname.includes('copy')
    const isEditMode = location.pathname.includes('edit') && requestId !== undefined

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isDirty, isValid },
    } = useForm<FormData>({
        defaultValues: {
            equipmentTypes: [
                {
                    equipmentType: undefined,
                    count: 1
                }
            ],
        }
    })
    const jobTask = watch('jobTask')
    const dateTimeTo = watch('dateTimeTo')

    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    const { data: detailsData, error: detailsError, isLoading: detailsIsLoading } = useGetJobRequestDetailsQuery(requestId, { skip: !requestId })
    const [sendRequest, { isLoading: sendIsLoading, isError: sendIsError, isSuccess: sendIsSuccess }] = useCreateJobRequestMutation()
    const [updateRequest, { isLoading: updateIsLoading, isError: updateIsError, isSuccess: updateIsSuccess }] = useUpdateJobRequestMutation()
    const { refetch: refetchWorkSchedule } = useGetWorkPlanQuery(ownerFilter === RequestOwnerFilter.MY, { skip: !sendIsSuccess && !updateIsSuccess })
    const { data: activityTypesData } = useGeActivityTypesQuery(jobTask?.block?.id, { skip: !jobTask?.block?.id })
    const { data: workScheduleData } = masterApi.endpoints.getWorkPlan.useQueryState(ownerFilter === RequestOwnerFilter.MY);

    const [editDisabled, setEditDisabled] = useState<boolean>(false)

    const teamId = useSelector((state: RootState) => state.master.teamId)

    useMount(() => {
        const cache = localStorage.getItem(StorageKeys.masterWorkPlace)
        if (!requestId && cache) {
            const workPlace = JSON.parse(cache) as WorkPlaceValue
            setValue('workPlace', workPlace)
        }
    })

    useEffect(() => {
        if (detailsData) {
            if (isCopyMode) {
                setValue("dateTimeOf", addHours(detailsData.dateTimeOf, 24))
                setValue("dateTimeTo", addHours(detailsData.dateTimeTo, 24))
            } else {
                setValue("dateTimeOf", detailsData.dateTimeOf)
                setValue("dateTimeTo", detailsData.dateTimeTo)
            }
            setValue("equipmentTypes", detailsData.equipmentTypes)
            setValue("materials", detailsData.materials)
            // setValue("duration", detailsData.duration)
            setValue("jobTask", detailsData.jobTask)
            setValue("workPlace", detailsData.workPlace)

            if (isCopyMode === false) {
                const disabled =
                    detailsData.hasFactSlots ||
                    isAfter(new Date(), detailsData.dateTimeTo) ||
                    [
                        MasterRequestState.IN_PROGRESS,
                        MasterRequestState.COMPLETED,
                        MasterRequestState.PART_COMPLETED,
                    ].includes(detailsData.state)

                setEditDisabled(disabled)
            }
        }
    }, [detailsData, isCopyMode, setValue])

    useEffect(() => {
        if (activityTypesData && activityTypesData.activityTypes.length > 0 && activityTypesData.activityTypes[0].resourceSets.length > 0) {
            setValue("equipmentTypes", activityTypesData.activityTypes.at(0)!.resourceSets.at(0)!.equipmentTypeParams.map((element) => ({
                count: element.count,
                equipmentType: {
                    id: element.equipmentTypeId,
                    title: element.equipmentTitle,
                }
            })))
        }
    }, [activityTypesData, setValue])

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const requestData = {
            state: detailsData?.state ? detailsData?.state : MasterRequestState.CREATED,
            teamId: teamId,
            name: data.jobTask.block.title,
            jobs: [
                {
                    id: detailsData?.jobId,
                    dateTimeOf: data.dateTimeOf.toISOString(),
                    dateTimeTo: data.dateTimeTo.toISOString(),
                    priority: CalculateRequestPriority({ workShift: workScheduleData?.shift, date: data.dateTimeOf }),
                    poiId: data.workPlace.cluster.id,
                    wellId: data.workPlace.well?.id,
                    location: data.workPlace.well?.id ?? data.workPlace.cluster.id,
                    title: data.jobTask.block.title,
                    name: data.jobTask.block.title,
                    equipmentTypeParams: data.equipmentTypes.map((element) => ({
                        equipmentTypeId: element.equipmentType.id,
                        countType: 'MIN',
                        count: element.count,
                    })),
                    materialRequirements: data.materials.map((element) => ({
                        materialId: element.material.id,
                        qty: element.count
                    })),
                }
            ],
        }

        if (isEditMode) {
            await updateRequest({
                id: requestId,
                body: requestData,
            })
        } else {
            await sendRequest(requestData)
        }
    }

    useEffect(() => {
        if (sendIsError || updateIsError) {
            toast.error('Произошла ошибка. Не удалось создать заявку')
        }
        if (sendIsSuccess || updateIsSuccess) {
            refetchWorkSchedule()
            navigate(-1)
        }
    }, [sendIsError, updateIsError, sendIsSuccess, updateIsSuccess, navigate, refetchWorkSchedule])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingBottom: 80 }}>
            <AppBar
                style={{ zIndex: 2 }}
                title="Заявка на работу"
                onBackPress={() => navigate(-1)}
            />

            {detailsIsLoading && !detailsError && <RequestDetailsSkeleton />}

            {!detailsIsLoading && !detailsError && <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}
            >
                <SelectWorkPlace
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='workPlace'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectJobTask
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='jobTask'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Начать в'
                    style={{ zIndex: 10 }}
                    name='dateTimeOf'
                    control={control}
                    rules={{ required: true, validate: (field: Date) => isBefore(field, dateTimeTo) }}
                />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Закончить в'
                    style={{ zIndex: 10 }}
                    name='dateTimeTo'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectEquipmentType
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='equipmentTypes'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectMaterial
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='materials'
                    control={control}
                />
            </Space>}
        </div>

        <div
            style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                left: 0,
                bottom: 0,
                width: '100%',
                height: '60px',
            }}
        >
            <Divider />
            <div
                style={{
                    paddingTop: 6,
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Button
                    view={isEditMode ? 'secondary' : 'primary'}
                    form="round"
                    label={isEditMode ? 'Сохранить' : 'Создать'}
                    width="full"
                    disabled={(!requestId && !isDirty) || !isValid}
                    loading={sendIsLoading || updateIsLoading}
                    type="submit"
                />
            </div>
        </div>
    </form>
}