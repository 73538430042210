import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@consta/uikit/TextField";
import { FieldLabel } from '@consta/uikit/FieldLabel';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { Space } from '../Space';
import { Text } from '@consta/uikit/Text';


export const SelectDuration = ({
  disabled,
  usePresets = true,
  label,
  name,
  rules,
  defaultValue,
  control,
  shouldUnregister,
  style,
  ...fieldProps
}: any) => {
  const context = useFormContext();

  const renderSelectDuration = ({ field, fieldState, formState }: any) => {
    const { onChange, onBlur, value } = field;

    return (

      <Space direction='vertical' size='xs'>
        <FieldLabel required={rules?.required}>{label ?? 'Длительность'}</FieldLabel>
        <FieldGroup>
          <TextField
            disabled={disabled}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(
                {
                  drurationHours: event.value,
                  drurationMinutes: value?.drurationMinutes
                }
              )
            }}
            placeholder='Введите'
            type="number"
            value={value?.drurationHours}
            incrementButtons={false}
          />
          <TextField
            disabled={disabled}
            style={{ width: 30 }}
            readOnly
            value='ч'
          />
          <TextField
            disabled={disabled}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(
                {
                  drurationHours: value?.drurationHours,
                  drurationMinutes: event.value,
                }
              )
            }}
            placeholder='Введите'
            type="number"
            value={value?.drurationMinutes}
            incrementButtons={false}
          />
          <TextField
            disabled={disabled}
            style={{ width: 55 }}
            readOnly
            value='мин'
          />
        </FieldGroup>
        {(usePresets && !disabled) ? <Space>
          <Text
            decoration="underline"
            size="s"
            onClick={() => {
              onChange({
                drurationMinutes: 30,
                drurationHours: 0,
              })
            }}>Полчаса</Text>
          <Text
            decoration="underline"
            size="s"
            onClick={() => {
              onChange({
                drurationMinutes: 0,
                drurationHours: 1,
              })
            }}>Час</Text>
          <Text
            decoration="underline"
            size="s"
            onClick={() => {
              onChange({
                drurationMinutes: 0,
                drurationHours: 2,
              })
            }}>2 часа</Text>
          <Text
            decoration="underline"
            size="s"
            onClick={() => {
              onChange({
                drurationMinutes: 0,
                drurationHours: 4,
              })
            }}>4 часа</Text>
        </Space> : <></>}
      </Space>
    );
  };

  return <Controller
    name={name}
    rules={{
      required: rules.required,
      validate: (field) => field.drurationHours + field.drurationMinutes > 0
    }}
    defaultValue={defaultValue}
    control={control || context.control}
    shouldUnregister={shouldUnregister}
    render={renderSelectDuration}
  />
}