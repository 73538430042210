import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Space } from "../../../../../components/Space";
import { FieldLabel } from '@consta/uikit/FieldLabel';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Button } from "@consta/uikit/Button";
import { SelectRoutePointController } from "./SelectRoutePointController";


export const SelectRoutePoint = ({
    disabled,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
}: any) => {
    const { fields, append, move } = useFieldArray({
        control,
        name: name,
        rules: {
            minLength: rules?.required ? 1 : undefined,
            required: rules?.required,
        }
    });

    const context = useFormContext()

    return <Space direction="vertical" size="s">
        <FieldLabel required={rules?.required} className={cnMixSpace({ mB: 'xs' })}>Точки объезда</FieldLabel>
        <>
            {fields.map((item, index) => (
                <Controller
                    key={item.id}
                    name={`${name}.${index}`}
                    rules={{
                        validate: (field) => field.oilField !== undefined && field.cluster !== undefined && (field.drurationMinutes + field.drurationHours > 0)
                    }}
                    defaultValue={defaultValue}
                    control={control || context.control}
                    shouldUnregister={shouldUnregister}
                    render={({ field }) => SelectRoutePointController({
                        disabled,
                        field,
                        name,
                        rules,
                        index,
                        control,
                        defaultValue,
                        shouldUnregister,
                        move,
                    })}
                />
            ))}
        </>
        <Button
            disabled={disabled}
            label="+ Добавить"
            view="ghost"
            onClick={() => append({
                oilField: undefined,
                cluster: undefined,
                drurationMinutes: 0,
                drurationHours: 0,
                comment: ''
            })} />
    </Space>
}