import { useNavigate } from "react-router"
import { AppBar } from "../../../components/AppBar"
import { Tabs } from '@consta/uikit/Tabs';
import { useState } from "react";
import { MechanicOfflineRequests } from "./MechanicOfflineRequests";
import { MechanicBindEquipmetRequests } from "./MechanicBindEquipmentRequests";
import { Button } from "@consta/uikit/Button";
import { IconRestart } from '@consta/icons/IconRestart';
import { useGetBindEquipmentRequestsQuery } from "./MechanicBindEquipmentRequests/services";
import { useGetRequestsQuery } from "./MechanicOfflineRequests/services";
import { useGetDisengageTypesQuery } from "../../../mdm/services";


const tabs: Item[] = [{ label: 'Сход с линии' }, { label: 'Привязки' }]
const getTabLabel = (item: Item) => item.label;

type Item = {
    label: string
}

export const MechanicRequests = () => {
    const navigate = useNavigate()

    const { refetch: refetchBindings } = useGetBindEquipmentRequestsQuery(undefined, { skip: false })
    const { refetch: refetchOffline } = useGetRequestsQuery(undefined, { skip: false })
    const { refetch: refetchDisengageTypes } = useGetDisengageTypesQuery(undefined, { skip: false })


    const [currentTab, setCurrentTab] = useState<Item | null>(tabs[0])

    return <div>
        <AppBar
            style={{ zIndex: 2 }}
            title="Заявки"
            onBackPress={() => navigate(-1)}
            actionBtn={
                <Button
                    size="s"
                    iconRight={IconRestart}
                    onlyIcon
                    view="ghost"
                    onClick={() => [
                        refetchOffline(),
                        refetchBindings(),
                        refetchDisengageTypes()
                    ]}
                />
            }
            tabs={
                <Tabs
                    style={{ position: 'fixed' }}
                    value={currentTab}
                    onChange={(e) => { setCurrentTab(e.value) }}
                    items={tabs}
                    getItemLabel={getTabLabel}
                />
            }
        />
        <div style={{ paddingTop: 100 }} >
            {currentTab === tabs[0] ? <MechanicOfflineRequests /> : <MechanicBindEquipmetRequests />}
        </div>
    </div>
}