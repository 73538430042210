import { useLocation, useNavigate, useParams } from "react-router"
import toast from 'react-hot-toast';
import { AppBar } from "../../../../components/AppBar";
import { Space } from "../../../../components/Space";
import { SubmitHandler, useForm } from "react-hook-form";
import { SelectRoutePoint } from "../components/SelectRoutePoint";
import { RoutePlaceValue } from "../components/SelectRoutePoint/types";
import { Divider } from "../../../../components/Divider";
import { Button } from "@consta/uikit/Button"
import { SelectDateTime } from "../../../../components/SelectDateTime";
import { MdmEquipmentItem } from "../../../../mdm/types";
import { SelectEquipmentItem } from "../components/SelectEquipmentItem";
import { useEffect, useState } from "react";
import { useGetWorkPlanQuery } from "../../services";
import { useCreateRouteRequestMutation, useGetRouteRequestDetailsQuery, useUpdateRouteRequestMutation } from "./services";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { RequestDetailsSkeleton } from "../components/RequestDetailsSkeleton";
import { addHours, isBefore } from "date-fns";
import { MasterRequestState, RequestOwnerFilter } from "../../home/types";


type FormData = {
    routePoints: RoutePlaceValue[]
    equipment: MdmEquipmentItem
    dateTimeOf: Date
    dateTimeTo: Date
}

export const MasterRouteRequest = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { requestId } = useParams()

    const isCopyMode = location.pathname.includes('copy')
    const isEditMode = location.pathname.includes('edit') && requestId

    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    const { data: detailsData, error: detailsError, isLoading: detailsIsLoading } = useGetRouteRequestDetailsQuery(requestId, { skip: !requestId })
    const [sendRequest, { isLoading: sendIsLoading, isError: sendIsError, isSuccess: sendIsSuccess }] = useCreateRouteRequestMutation()
    const [updateRequest, { isLoading: updateIsLoading, isError: updateIsError, isSuccess: updateIsSuccess }] = useUpdateRouteRequestMutation()
    const { refetch: refetchWorkSchedule } = useGetWorkPlanQuery(ownerFilter === RequestOwnerFilter.MY, { skip: !sendIsSuccess && !updateIsSuccess })

    const [editDisabled, setEditDisabled] = useState<boolean>(false);

    const teamId = useSelector((state: RootState) => state.master.teamId)

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isDirty, isValid },
    } = useForm<FormData>({
        defaultValues: {
            routePoints: [
                {
                    oilField: undefined,
                    cluster: undefined,
                    drurationMinutes: 30,
                    drurationHours: 0,
                }
            ]
        }
    })

    const dateTimeTo = watch('dateTimeTo')

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const requestData = {
            state: detailsData?.state ? detailsData?.state : MasterRequestState.CREATED,
            teamId: teamId,
            dateTimeOf: data.dateTimeOf.toISOString(),
            dateTimeTo: data.dateTimeTo.toISOString(),
            isSignificantChanges: false,
            name: 'Объезд',
            title: 'Объезд',
            equipmentItemId: data.equipment.id,
            locations: data.routePoints.map((route) => ({
                id: route.id,
                comment: route.comment,
                priority: 50,
                duration: Number(route.drurationMinutes) + Number(route.drurationHours * 60),
                location: route.cluster.id,
                locationId: route.cluster.id,
                deleted: route.deleted,
            })),
        }

        if (isEditMode) {
            await updateRequest({
                id: requestId,
                body: requestData
            })
        } else {
            await sendRequest(requestData)
        }
    }

    useEffect(() => {
        if (sendIsError || updateIsError) {
            toast.error('Произошла ошибка. Не удалось создать заявку')
        }
        if (sendIsSuccess || updateIsSuccess) {
            refetchWorkSchedule()
            navigate(-1)
        }
    }, [sendIsError, updateIsError, sendIsSuccess, updateIsSuccess, navigate, refetchWorkSchedule])

    useEffect(() => {
        if (detailsData) {
            if (isCopyMode) {
                setValue("dateTimeOf", addHours(detailsData.dateTimeOf, 24))
                setValue("dateTimeTo", addHours(detailsData.dateTimeTo, 24))
            } else {
                setValue("dateTimeOf", detailsData.dateTimeOf)
                setValue("dateTimeTo", detailsData.dateTimeTo)
            }
            setValue("equipment", detailsData.equipment)
            setValue("routePoints", detailsData.routePoints)

            if (isCopyMode === false) {
                const disabled =
                    isCopyMode === false &&
                    [
                        MasterRequestState.IN_PROGRESS,
                        MasterRequestState.COMPLETED,
                        MasterRequestState.NOT_PERFORMED,
                    ].includes(detailsData.state)
                setEditDisabled(disabled)
            }
        }
    }, [detailsData, isCopyMode, setValue])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingBottom: 80 }}>
            <AppBar
                style={{ zIndex: 2 }}
                title="Заявка на объезд"
                onBackPress={() => navigate(-1)}
            />

            {detailsIsLoading && !detailsError && <RequestDetailsSkeleton />}

            {!detailsIsLoading && !detailsError && <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}
            >
                <SelectRoutePoint
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='routePoints'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectEquipmentItem
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='equipment'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Начать не ранее'
                    style={{ zIndex: 10 }}
                    name='dateTimeOf'
                    control={control}
                    rules={{ required: true, validate: (field: Date) => isBefore(field, dateTimeTo) }}
                />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Закончить не позднее'
                    style={{ zIndex: 10 }}
                    name='dateTimeTo'
                    control={control}
                    rules={{ required: true }}
                />

            </Space>}
        </div>

        <div
            style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                left: 0,
                bottom: 0,
                width: '100%',
                height: '60px',
            }}
        >
            <Divider />
            <div
                style={{
                    paddingTop: 6,
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Button
                    view={isEditMode ? 'secondary' : 'primary'}
                    form="round"
                    label={isEditMode ? 'Сохранить' : 'Создать'}
                    width="full"
                    disabled={(!requestId && !isDirty) || !isValid}
                    loading={sendIsLoading || updateIsLoading}
                    type="submit"
                />
            </div>
        </div>
    </form>
}