import { FC, Fragment, useEffect } from "react"
import { GetShiftPeriodDescription, GetShiftPeriodType, ShiftType } from "../../../../utils/workShifts"
import { parseISO } from "date-fns"
import { CollapseGroup, DefaultItem } from "@consta/uikit/CollapseGroup"
import { ShiftLabel } from "../../../../components/ShiftLabel"
import { Divider } from "../../../../components/Divider"
import styles from './styles.module.css';
import { MasterRequest, MasterScheduleData, RequestOwnerFilter } from "../types"
import { MasterRequestItem } from "./MasterRequestItem"
import { useCancelRequestMutation, useDeleteRequestMutation } from "../../services"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { RootState } from "../../../../store"
import { StorageKeys } from "../../../../utils/storage-keys"


interface Props {
    hidden: boolean
    data: MasterScheduleData
    onUpdate: () => void
}

export const MasterWorkPlan: FC<Props> = ({ data, hidden, onUpdate }) => {
    const [deleteRequest, { isError: isDeleteError, isSuccess: isDeleteSuccess }] = useDeleteRequestMutation()
    const [cancelRequest, { isError: isCancelError, isSuccess: isCancelSuccess }] = useCancelRequestMutation()

    const username = useSelector((state: RootState) => state.user.username)
    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    useEffect(() => {
        if (isDeleteError) {
            toast.error('Произошла ошибка. Не удалось удалить заяку')
        }
        if (isCancelError) {
            toast.error('Произошла ошибка. Не удалось отменить заяку')
        }
    }, [isDeleteError, isCancelError])

    useEffect(() => {
        if (isDeleteSuccess || isCancelSuccess) {
            onUpdate()
        }
    }, [isDeleteSuccess, isCancelSuccess, onUpdate])

    var requests = [...data.requests]

    if (localStorage.getItem(StorageKeys.passangerModeEnabled) === 'true' && ownerFilter) {
        switch (ownerFilter) {
            case RequestOwnerFilter.ALL:
                requests = [...data.requests]
                break
            case RequestOwnerFilter.MY:
                requests = requests.filter(element => element.createdBy === username)
                break
        }
    }

    requests.sort((a, b) => parseISO(a.beginDate).getTime() - parseISO(b.beginDate).getTime())

    const previousShift: MasterRequest[] = requests.filter((request) => {
        var shiftType = GetShiftPeriodType({ workShift: data.shift, date: parseISO(request.beginDate) })
        return shiftType === ShiftType.previous
    }) ?? []

    const currentShift: MasterRequest[] = requests.filter((request) => {
        var shiftType = GetShiftPeriodType({ workShift: data.shift, date: parseISO(request.beginDate) })
        return shiftType === ShiftType.current
    }) ?? []

    const nextShift: MasterRequest[] = requests.filter((request) => {
        var shiftType = GetShiftPeriodType({ workShift: data.shift, date: parseISO(request.beginDate) })
        return shiftType === ShiftType.next
    }) ?? []

    const itemsTasks: DefaultItem[] = [
        {
            label: <ShiftLabel
                description={GetShiftPeriodDescription({ workShift: data.shift, type: ShiftType.previous })}
                count={previousShift.length}
            />,
            content: previousShift.length > 0 ? <div style={{
                paddingTop: 'var(--space-s)',
                paddingBottom: 'var(--space-s)',
            }}>
                {previousShift.map((request, index) => {
                    return <Fragment key={request.id}>
                        <MasterRequestItem
                            item={request}
                            onDeleted={() => deleteRequest(request.id)}
                            onCanceled={() => cancelRequest({ requestId: request.id })}
                        />
                        {index !== previousShift.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                    </Fragment>
                })
                }
            </div> : <></>,
        },
        {
            label: <ShiftLabel
                description={GetShiftPeriodDescription({ workShift: data.shift, type: ShiftType.current })}
                count={currentShift.length}
            />,
            content: currentShift.length > 0 ? <div style={{
                paddingTop: 'var(--space-s)',
                paddingBottom: 'var(--space-s)',
            }}>
                {currentShift.map((request, index) => {
                    return <Fragment key={request.id}>
                        <MasterRequestItem
                            item={request}
                            onDeleted={() => deleteRequest(request.id)}
                            onCanceled={() => cancelRequest({ requestId: request.id })}
                        />
                        {index !== currentShift.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                    </Fragment>
                })
                }
            </div> : <></>,
        },
        {
            label: <ShiftLabel
                description={GetShiftPeriodDescription({ workShift: data.shift, type: ShiftType.next })}
                count={nextShift.length}
            />,
            content: nextShift.length > 0 ? <div style={{
                paddingTop: 'var(--space-s)',
                paddingBottom: 'var(--space-s)',
            }}>
                {nextShift.map((request, index) => {
                    return <Fragment key={request.id}>
                        <MasterRequestItem
                            item={request}
                            onDeleted={() => deleteRequest(request.id)}
                            onCanceled={() => cancelRequest({ requestId: request.id })}
                        />
                        {index !== nextShift.length - 1 && <Divider style={{ marginTop: 12, marginBottom: 12 }} />}
                    </Fragment>
                })
                }
            </div> : <></>,
        }
    ]

    return <div>
        <CollapseGroup
            className={styles.collapseGroup}
            style={{
                visibility: hidden ? 'hidden' : 'visible',
                marginTop: 10,
            }}
            divider={true}
            items={itemsTasks}
            size="l"
            view="ghost"
            iconPosition="right"
        />
    </div>

}