import { useState } from "react";
import { TextField } from "@consta/uikit/TextField";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import React from 'react';
import { Button } from "@consta/uikit/Button";
import { IconAdd } from '@consta/icons/IconAdd';
import { IconRemove } from '@consta/icons/IconRemove';
import { Text } from '@consta/uikit/Text';
import { Space } from "../../Space";
import { EquipmetTypeList } from "./EquipmetTypeList";
import { BottomSheetTitleHeader } from "../../BottomSheetTitleHeader";
import { Modal } from '@consta/uikit/Modal';


export const SelectEquipmentTypeController = ({
    single,
    disabled,
    field,
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
    style,
    index,
    remove,
    ...fieldProps
}: any) => {

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const refBottomSheet = React.useRef<SheetRef>()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const renderSelectEquipmentElement = () => {
        const { onChange, onBlur, value, ref } = field

        return (
            <div key={field.id}>
                <Space>
                    <TextField
                        disabled={disabled}
                        onClick={() => {
                            setIsDialogOpen(true);
                        }}
                        style={{ width: '100%' }}
                        {...fieldProps}
                        onBlur={onBlur}
                        ref={ref}
                        placeholder="Тип техники"
                        type="textarea"
                        readOnly
                        value={value?.equipmentType && `${value.equipmentType.title}`}
                    />
                    {!single ? <Space size="2xs">
                        <Button
                            disabled={disabled}
                            iconRight={IconRemove}
                            onlyIcon
                            view="ghost"
                            size="xs"
                            onClick={() => {
                                if (value.count > 1) {
                                    onChange({
                                        equipmentType: value.equipmentType,
                                        count: --value.count
                                    })
                                } else {
                                    setIsModalOpen(true)
                                }
                            }}
                        />
                        <TextField
                            disabled={disabled || !value.equipmentType}
                            style={{ width: 50 }}
                            onBlur={onBlur}
                            onChange={(event) => {
                                if (event.value && event.value?.length <= 2) {
                                    onChange({
                                        equipmentType: value.equipmentType,
                                        count: event.value,
                                    })
                                }
                                if (!event.value) {
                                    onChange({
                                        equipmentType: value.equipmentType,
                                        count: 0,
                                    })
                                }
                            }}
                            type="number"
                            value={value.count === 0 ? undefined : value.count}
                            incrementButtons={false}
                        />
                        <Button
                            iconRight={IconAdd}
                            disabled={disabled || !value.equipmentType}
                            onlyIcon
                            view="ghost"
                            size="xs"
                            onClick={() => {
                                if (value.count < 99) {
                                    onChange({
                                        equipmentType: value.equipmentType,
                                        count: ++value.count
                                    })
                                }
                            }}
                        />
                    </Space> : <></>}

                </Space>
                <Sheet
                    ref={refBottomSheet}
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    snapPoints={[window.innerHeight - 65, 0]}
                    detent="full-height"
                    initialSnap={0}
                >
                    <Sheet.Container>
                        <Sheet.Header>
                            <Theme preset={presetGpnDefault}>
                                <BottomSheetTitleHeader
                                    title='Выбор типа техники'
                                    onClose={() => {
                                        refBottomSheet.current?.snapTo(1)
                                    }}
                                />
                            </Theme>
                        </Sheet.Header>
                        <Sheet.Content disableDrag={true}>
                            <Sheet.Scroller draggableAt="top">
                                <Theme preset={presetGpnDefault} style={{ paddingBottom: 80 }}>
                                    <EquipmetTypeList
                                        onSelect={(item) => {
                                            onChange({
                                                equipmentType: item,
                                                count: value.count
                                            })
                                            setIsDialogOpen(false)
                                        }}
                                    />
                                </Theme>
                            </Sheet.Scroller>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop />
                </Sheet>

                <Modal
                    isOpen={isModalOpen}
                    hasOverlay
                    onClickOutside={() => setIsModalOpen(false)}
                    onEsc={() => setIsModalOpen(false)}
                    style={{
                        zIndex: 100,
                    }}
                >   <div style={{ padding: 'var(--space-s)' }}>
                        <Text as="p" size="m" view="secondary" lineHeight="m">Удалить технику из списка?</Text>
                        <Space>
                            <div>
                                <Button
                                    size="m"
                                    view="primary"
                                    label="Да"
                                    width="default"
                                    onClick={() => {
                                        setIsModalOpen(false)
                                        remove(index)
                                    }}
                                />
                            </div>
                            <div>
                                <Button
                                    size="m"
                                    view="ghost"
                                    label="Отмена"
                                    width="default"
                                    onClick={() => setIsModalOpen(false)}
                                />
                            </div>
                        </Space>
                    </div>
                </Modal>
            </div>
        );
    };

    return renderSelectEquipmentElement()
}