import { useLocation, useNavigate, useParams } from "react-router"
import { WorkPlaceValue } from "../MasterJobRequest/types"
import { useSelector } from "react-redux"
import { RootState } from "../../../../store"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { AppBar } from "../../../../components/AppBar"
import { Space } from "../../../../components/Space"
import { SelectTransferPurpose } from "../components/SelectTransferPurpose"
import { TextField } from "@consta/uikit/TextField"
import { Divider } from "../../../../components/Divider"
import { SelectWorkPlace } from "../../../../components/SelectWorkPlace"
import { SelectDestinationPoints } from "../components/SelectDestinationPoints"
import { Text } from '@consta/uikit/Text';
import { OrderTypeValue } from "../components/SelectOrderType/types"
import { SelectOrderType } from "../components/SelectOrderType"
import { Button } from "@consta/uikit/Button"
import { PhoneTextFiled } from "../components/PhoneTextFiled"
import { MdmRequestPurposes } from "../../../../mdm/types"
import { SelectEquipmentType } from "../../../../components/SelectEquipmentType"
import { SelectedEquimpentTypeCount } from "../../../../components/SelectEquipmentType/types"
import { useCreatePassengerTransportRequestMutation, useGetPassengerRequestDetailsQuery, useUpdatePassangerRequestMutation } from "./services"
import { MasterRequestState, RequestOwnerFilter } from "../../home/types"
import { useEffect } from "react"
import { useGetWorkPlanQuery } from "../../services"


type FormData = {
    transferPurpose: MdmRequestPurposes
    phone: string
    comment: string
    equipmentType: SelectedEquimpentTypeCount[]
    passCount: string
    pickupLocation: WorkPlaceValue
    destinationLocations: WorkPlaceValue[]
    orderType: OrderTypeValue
}

export const MasterPassengerRequest = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { requestId } = useParams()

    const isEditMode = location.pathname.includes('edit') && requestId

    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    const [sendRequest, { isLoading: sendIsLoading, isError: sendIsError, isSuccess: sendIsSuccess }] = useCreatePassengerTransportRequestMutation()
    const [updateRequest, { isLoading: updateIsLoading, isError: updateIsError, isSuccess: updateIsSuccess }] = useUpdatePassangerRequestMutation()
    const { data: detailsData } = useGetPassengerRequestDetailsQuery(requestId, { skip: !requestId })

    const { refetch: refetchWorkSchedule } = useGetWorkPlanQuery(ownerFilter === RequestOwnerFilter.MY, { skip: !sendIsSuccess && !updateIsSuccess })

    const teamId = useSelector((state: RootState) => state.master.teamId)

    const {
        handleSubmit,
        control,
        setValue,
        formState: { isDirty, isValid },
    } = useForm<FormData>({
        defaultValues: {
            orderType: OrderTypeValue.FREE,
            equipmentType: [
                {
                    equipmentType: undefined,
                    count: 1
                }
            ],
            destinationLocations: [
                {

                }
            ]
        }
    })

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const locationsIds = []
        locationsIds.push(data.pickupLocation.cluster.id)
        data.destinationLocations.forEach(element => locationsIds.push(element.cluster.id))
        const checkSet = new Set(locationsIds);
        if (locationsIds.length !== checkSet.size) {
            toast.error('Не возможно создать заявку! Выбраны одинаковые точки посадки или назначения')
            return
        }

        const requestData = {
            name: `Поездка из ${data.pickupLocation.cluster.title} на ${data.destinationLocations.map(element => element.cluster.title).join(', ')}`,
            state: detailsData?.state ? detailsData?.state : MasterRequestState.CONFIRMED,
            teamId: teamId,
            requestPurpose: data.transferPurpose.id,
            phoneNumber: data.phone,
            comment: data.comment,
            fixOrder: data.orderType === OrderTypeValue.FIXED,
            equipmentTypeId: data.equipmentType.at(0)!.equipmentType.id,
            numberOfPassengers: Number(data.passCount),
            pickUpLocation: {
                poiId: data.pickupLocation.cluster.id,
                id: data.pickupLocation.cluster.id,
                wellPadGroupTitle: data.pickupLocation.oilField.title,
                wellPadTitle: data.pickupLocation.cluster.title,
            },
            dropLocations: data.destinationLocations?.map((element) => ({
                poiId: element.cluster.id,
                id: element.cluster.id,
                wellPadGroupTitle: element.oilField.title,
                wellPadTitle: element.cluster.title,
            })),
        }

        if (isEditMode) {
            await updateRequest({
                id: requestId,
                body: requestData
            })
        } else {
            await sendRequest(requestData)
        }
    }

    useEffect(() => {
        if (sendIsError || updateIsError) {
            toast.error('Произошла ошибка. Не удалось создать заявку')
        }
        if (sendIsSuccess || updateIsSuccess) {
            refetchWorkSchedule()
            navigate(-1)
        }
    }, [sendIsError, updateIsError, sendIsSuccess, updateIsSuccess, navigate, refetchWorkSchedule])

    useEffect(() => {
        if (detailsData) {
            setValue("transferPurpose", detailsData.transferPurpose)
            setValue("phone", detailsData.phoneNumber)
            setValue("comment", detailsData.comment)
            setValue("orderType", detailsData.fixOrder ? OrderTypeValue.FIXED : OrderTypeValue.FREE)
            setValue("passCount", String(detailsData.numberOfPassengers))
            setValue("equipmentType", detailsData.equipmentType)
            setValue("pickupLocation", detailsData.pickupLocation)
            setValue("destinationLocations", detailsData.destinationLocations)
        }
    }, [detailsData, setValue])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingBottom: 80 }}>
            <AppBar
                style={{ zIndex: 2 }}
                title="Пассажирский транспорт"
                onBackPress={() => navigate(-1)}
            />

            <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}
            >

                <SelectTransferPurpose
                    style={{ zIndex: 10 }}
                    name='transferPurpose'
                    control={control}
                    rules={{ required: true }}
                />

                <Controller
                    name="phone"
                    control={control}
                    rules={{
                        required: true,
                        validate: (field) => field.length === 16
                    }}
                    render={PhoneTextFiled}
                />

                <SelectEquipmentType
                    single={true}
                    title='Тип автомобиля'
                    style={{ zIndex: 10 }}
                    name='equipmentType'
                    control={control}
                    rules={{ required: true }}
                />

                <Controller
                    name="passCount"
                    control={control}
                    rules={{
                        required: true,
                        validate: (field) => Number(field) > 0,
                    }}
                    render={({ field }) => <TextField
                        {...field}
                        required
                        onChange={(value) => field.onChange(value.value)}
                        type="number"
                        label='Количество пассажиров'
                        placeholder="Кол-во"
                        incrementButtons={false}
                    />}
                />

                <Divider />

                <SelectWorkPlace
                    label='Точка посадки'
                    style={{ zIndex: 10 }}
                    name='pickupLocation'
                    control={control}
                    rules={{ required: true }}
                    level={2}
                />

                <Text view="primary" size="s" weight="semibold" spacing="xs">ТОЧКИ НАЗНАЧЕНИЯ</Text>

                <SelectDestinationPoints
                    style={{ zIndex: 10 }}
                    name='destinationLocations'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectOrderType
                    name='orderType'
                    control={control}
                    rules={{ required: true }}
                />

                <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => <TextField
                        {...field}
                        onChange={(value) => field.onChange(value.value)}
                        type="textarea"
                        label='Комментарий'
                        placeholder="Введите комментарий"
                        rows={3}
                    />}
                />

            </Space>
        </div>

        <div
            style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                left: 0,
                bottom: 0,
                width: '100%',
                height: '60px',
            }}
        >
            <Divider />
            <div
                style={{
                    paddingTop: 6,
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Button
                    view={isEditMode ? 'secondary' : 'primary'}
                    form="round"
                    label={isEditMode ? 'Сохранить' : 'Создать'}
                    width="full"
                    disabled={(!requestId && !isDirty) || !isValid}
                    loading={sendIsLoading || updateIsLoading}
                    type="submit"
                />
            </div>
        </div>
    </form>
}