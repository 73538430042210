import { useNavigate } from "react-router"
import { AppBar } from "../../components/AppBar"
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useState } from "react";


export const QrScanner = () => {
    const navigate = useNavigate()
    const [data, setData] = useState('');


    return <div>
        <AppBar
            style={{ zIndex: 2 }}
            title="Сканирование QR кода"
            onBackPress={() => navigate(-1)}
        />

        <div
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
                marginTop: 'var(--space-l)',
                paddingTop: 60,
            }}
        >
            <BarcodeScannerComponent
                onUpdate={(err, result) => {
                    if (result) setData(result.getText())
                }}
            />
            <p>{data}</p>
        </div>
    </div>
}